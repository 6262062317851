import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import { capitalizeFirstLetter, lowerFirstLetter, getFirtsImage, getHrefCall, getTelefono, settingsSlider } from '../../helpers/helpers';
import StitioIndividualSideBar from './StitioIndividualSideBar';
import { useTranslation } from 'react-i18next';

const SitioIndividualContainer =({sitio_, tipo_, lugaresCercanos_})=>{

    const [ sitio, setSitio ] = useState()
    const [ tipo, setTipo ] = useState("")
    const [ lugaresCercanos, setLugaresCercanos ] = useState([])
    const [ sitiosS, setSitiosS ] = useState([])
    const { t } = useTranslation('global')
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';


    useEffect(()=>{
        if(tipo_ === "operadoresTuristicos"){
            
            setTipo("operadores Turísticos")
        }else{
            setTipo(tipo_)
        }
        setSitio(sitio_)
        
        if(tipo_ === "oficinasTuristicas"){
            setTipo("oficinas Turísticas")
            setLugaresCercanos(lugaresCercanos_.slice(0, 3))
            setSitiosS(lugaresCercanos_.slice(3, lugaresCercanos_.length))
        }else{
            if(tipo_ === "rentacars"){
                setTipo("rent a Cars")
                setLugaresCercanos(lugaresCercanos_.slice(0, 3))
                setSitiosS(lugaresCercanos_.slice(3, lugaresCercanos_.length))
            }else{
                setLugaresCercanos(lugaresCercanos_)
            }
            
        }
        
    }, [sitio_, tipo_, lugaresCercanos_])

    const getNombres = ()=>{
        let n = []
        if(sitio?.nombre){
            n.push(sitio.nombre)
        }
        for(let i=0; i<lugaresCercanos.length; i++){
            n.push(lugaresCercanos[i].nombre)
        }
        return n
    }

    const getCategorias = ()=>{
        let c = []
        if(sitio?.categorias && sitio.categorias.length > 0){
            for(let i=0; i<sitio.categorias.length; i++){
                c.push(sitio.categorias[i].nombre)
            }
        }
        return c
    } 

    const haveSlider = ()=>{
        if(sitio?.imagenes){
            if(sitio.imagenes.length > 0){
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <React.Fragment>
                                <Slider {...settingsSlider(1, 1, 1, 1)} className="sitio_single_slider">
                                    {
                                        sitio.imagenes.map((img, i)=>{
                                            return (
                                                <div className="iitem" key={i}>
                                                    <div className="sitio_single_item">
                                                        <img src={img.url} alt=""/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </React.Fragment>
                        </div>
                    </div>
                )
            }
        }
    }

    const haveDescription = ()=>{
        let descripcion = language === ESPANOL ? sitio?.descripcion : sitio?.descripcionIngles;
        if (!descripcion) {
            descripcion = language === ESPANOL ? sitio?.descripcionIngles : sitio?.descripcion;
        }
        if(descripcion){
            return (
                <p className="f_400 mb-30 text-justify">{descripcion}</p>
            )
        }
    }

    const haveCategorias = ()=>{
        if(sitio?.categorias){
            if(sitio.categorias.length > 0){
                return (
                    <div className="post_tag">
                        <div className="post-nam"> { t('global.categories') }: </div>
                        {
                            sitio.categorias.map((c, i)=>{
                                return <a  key={i}>{translateCategoriesName(c)}</a>
                            })
                        }
                    </div>
                )
            }
        }
    }

    const translateCategoriesName = (category) => {
        let name = language === ESPANOL ? category?.nombre : category?.nombreIngles;
        if (!name) {
            name = language === ESPANOL ? category?.nombreIngles : category?.nombre;
        }
        return name;
    }

    const getContactoIcon = (tipoContacto) => {
        if(tipoContacto === "Página web"){
            return "ti-world"
        }else if(tipoContacto === "Email"){
            return "ti-email"
        }else if(tipoContacto === "Teléfono"){
            return "ti-mobile"
        }else if(tipoContacto === "Facebook"){
            return "ti-facebook"
        }else if(tipoContacto === "Instagram"){
            return "ti-instagram"
        }
    }

    const getContactoTipo = (contacto) => {
        if(contacto.tipo === "Facebook" || contacto.tipo === "Instagram" || contacto.tipo === "Página web"){
            return contacto.tipo
        }else{
            return contacto.valor
        }
    }

    const getContactoHref = (contacto) =>{
        if(contacto.tipo === "Facebook" || contacto.tipo === "Instagram" || contacto.tipo === "Página web"){
            return contacto.valor
        }else{
            if(contacto.tipo === "Email"){
                return "mailto:"+contacto.valor
            }else if(contacto.tipo === "Teléfono"){
                return getHrefCall(contacto.valor)
            }
        }
    }

    const haveContactos = () => {
        if(sitio?.contactos){
            if(sitio.contactos.length > 0){
                return (
                    <div className="post_share mb-30">
                        {
                            sitio.contactos.map((c, i)=>{
                                return <a target="_new" href={getContactoHref(c)} key={i}><i className={getContactoIcon(c.tipo)}></i>{getContactoTipo(c)}</a>
                            })
                        }
                    </div>
                )
            }
        }
    }

    const haveUbicacion = () => {
        if(sitio?.ubicacion){
            return (
                <>
                    <div className="post-nam">
                        <a ><i className="icon_pin_alt"></i>{ t('global.location') }</a>
                    </div>
                    <p className="f_400 mt_05">{`${sitio.ubicacion.provincia}, ${sitio.ubicacion.canton}, ${sitio.ubicacion.distrito}, ${sitio.ubicacion.detalle || ""}`}</p>
                </>
                
            )
        }
    }

    const getTitleLugaresCercanos = () =>{
        if(tipo === "oficinas Turísticas" || tipo === "rent a Cars"){
            return t('global.more') + ` ${getTipoTraducido(tipo)}`
        }else{
            return `${getTipoTraducido(tipo)} ` + t('global.near_there')
        }
    }

    const getUrl = (s) => {
        let t=""
        t=tipo;
        // if(tipo === "Atracciones"){
        //     t = "atracciones"
        // }else if(tipo === "Hospedaje"){
        //     t = "hospedaje"
        // }else if(tipo === "Restaurantes"){
        //     t = "restaurantes"
        // }else 
        if(tipo==="operadores Turísticos"){
            t = "operadoresTuristicos"
        }else if(tipo === "oficinas Turísticas"){
            t = "oficinasTuristicas"
        }else if(tipo === "rent a Cars"){
            t = "rentacars"
        }

        return "/sitio/"+t+"/"+s.id
    }

    const getTipoTraducido = (tipo) => {
        let nombre = '';
      
        if (tipo === "atracciones") {
          nombre = t('global.attractions');
        } else if (tipo === "hospedaje") {
          nombre = t('global.lodgings');
        } else if (tipo === "restaurantes") {
          nombre = t('global.restaurants');
        } else if (tipo === "operadores Turísticos") {
          nombre = t('global.tour_operators');
        } else if (tipo === "oficinas Turísticas") {
          nombre = t('global.touristic_offices');
        } else {
          nombre = t('global.rental_cars');
        }
        return capitalizeFirstLetter(nombre);
    }

    const haveInsigniaAccessibilities = () => {
        return (
            <>
                <div className='mb-30'>
                    {tipo_ !== 'rentacars' && sitio?.declaracionTuristica === true && (
                        <div style={{alignItems: 'center'}}>
                            <img style={{width:'45px'}} alt='declaracionTuristica' src={require("../../img/declaracionTuristica.png")} />
                            <p style={{display: 'inline', marginLeft: '5px'}}>{t('accessibility.tourist_declaration')}</p>
                        </div>
                    )}
                </div>
            </>
        )
    }

    const haveAccessibilities = () => {
        if (sitio?.discapacidadVisual || sitio?.discapacidadAuditiva || sitio?.discapacidadFisica || sitio?.discapacidadCognitiva || sitio?.discapacidadSicosocial || sitio?.permitenNinos || sitio?.permitenMascotas) {
            return (
                <div className="post_tag">
                    <div className="post-nam">{t('accessibility.accessibilities')}: </div>
                    <div>
                        {tipo_ !== 'rentacars' && sitio?.permitenNinos === true && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/kids.png")} /> {t('accessibility.kids_friendly')}
                        </a>}
                        {tipo_ !== 'rentacars' && sitio?.permitenMascotas === true && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/mascota.png")} /> {t('accessibility.pet_friendly')}
                        </a>}
                        {sitio?.discapacidadVisual && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/vista.png")} /> {t('accessibility.visual')}
                        </a>}
                        {sitio?.discapacidadAuditiva && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/auditiva.png")} /> {t('accessibility.auditory')}
                        </a>}
                        {sitio?.discapacidadFisica && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/fisica.png")} /> {t('accessibility.physical')}
                        </a>}
                        {sitio?.discapacidadCognitiva && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/congnitiva.png")} /> {t('accessibility.cognitive')}
                        </a>}
                        {sitio?.discapacidadSicosocial && <a style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <img style={{width:'45px', marginRight: '5px'}} alt='declaracionTuristica' src={require("../../img/sicosocial.png")} /> {t('accessibility.psychosocial')}
                        </a>}
                         
                    </div>
                </div>
            );
        } else {
            return null; // No accessibilities to display
        }
    }
    
    
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                {
                    haveSlider()
                }
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <div className="blog_content">
                                <div className="entry_post_info">
                                    <a>{getTipoTraducido(tipo)}</a>
                                </div>
                                <a>
                                    <h5 className="f_p f_size_20 f_500 t_color mb_15">{sitio?.nombre}</h5>
                                </a>
                                {
                                    haveInsigniaAccessibilities()
                                }
                                {
                                    haveAccessibilities()
                                }
                                {
                                    haveDescription()
                                }
                                {
                                    haveCategorias()
                                }
                                {
                                    haveContactos()
                                }
                                {
                                    haveUbicacion()
                                }
                                
                                
                            </div>
                        </div>
                        {
                            lugaresCercanos.length > 0 &&
                        
                                <div className="blog_post">
                                    <div className="widget_title">
                                        <h3 className="f_p f_size_20 t_color3">{getTitleLugaresCercanos()}</h3>
                                        <div className="border_bottom"></div>
                                    </div>
                                    <div className="row">
                                        {
                                            lugaresCercanos.map((lugar, i)=>{
                                                return (
                                                    <div className="col-lg-4 col-sm-6" key={i}>
                                                        <div className="blog_post_item">
                                                            <div className="blog_img">
                                                                <img src={getFirtsImage(lugar)} alt=""/>
                                                            </div>
                                                            <div className="blog_content">
                                                                <div className="entry_post_info">
                                                                    {capitalizeFirstLetter(tipo)}
                                                                </div>
                                                                <a href={getUrl(lugar)}>
                                                                    <h5 className="f_p f_size_16 f_500 t_color">{lugar.nombre}</h5>
                                                                </a>
                                                                <p className="f_400 mb-0">{lugar.ubicacion.distrito}<br/>{getTelefono(lugar.contactos)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-4">
                        <StitioIndividualSideBar  tipo={capitalizeFirstLetter(tipo)} nombresR = {getNombres()} categoriasR = {getCategorias()} sitiosS={sitiosS}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SitioIndividualContainer;