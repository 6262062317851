import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTopRoute from "./ScrollToTopRoute";
import NotFound from "./Pages/404";
import homerutasturisticas from "./Pages/Home-rutas-turisticas";
import Atracciones from "./Pages/Atracciones";
import Restaurantes from "./Pages/Restaurantes";
import Hospedaje from "./Pages/Hospedaje";
import OficinasTuristicas from "./Pages/Oficinas-turisticas";
import RentaCars from "./Pages/Renta-cars";
import OperadoresTuristicos from "./Pages/OperadoresTuristicos.js";
import SitioIndividual from "./Pages/SitioIndividual";
import SearchPage from "./Pages/SearchPage";
import PerezZeledon from "./Pages/PerezZeledon";
import Distrito from "./Pages/Distrito";
import Contacto from "./Pages/Contacto";
import Mapa from "./Pages/Mapa";
import Countdown from "./Pages/Countdown";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={homerutasturisticas} />
          <ScrollToTopRoute path="/inicio" component={homerutasturisticas} />
          <ScrollToTopRoute path="/atracciones" component={Atracciones} />
          <ScrollToTopRoute path="/restaurantes" component={Restaurantes} />
          <ScrollToTopRoute path="/hospedaje" component={Hospedaje} />
          <ScrollToTopRoute path="/oficinas-turisticas" component={OficinasTuristicas} />
          <ScrollToTopRoute path="/renta-cars" component={RentaCars} />
          <ScrollToTopRoute path="/operadores-turisticos" component={OperadoresTuristicos} />
          <ScrollToTopRoute path="/sitio/:tipo/:id" component={SitioIndividual} />
          <ScrollToTopRoute path="/buscar/:tipo/:filtro/:categorias/:pagina/:accesabilitiesInPath/:othersInPath" component={SearchPage} />
          <ScrollToTopRoute path="/perezzeledon" component={PerezZeledon} />
          <ScrollToTopRoute path="/distritos/:distrito" component={Distrito} />
          <ScrollToTopRoute path="/contacto" component={Contacto} />
          <ScrollToTopRoute path="/mapas" component={Mapa} />
          <ScrollToTopRoute path="/final/countdown/9/mayo" component={Countdown} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
