import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterRutas from "../components/Footer/FooterRutas";
import FooterData from "../components/Footer/FooterData";
import RutasTuristicasHomeBanner from "../components/Banner/RutasTuristicasHomeBanner";
import DistritosShowCase from "../components/DistritosShowCase";
import HomeBienvenida from "../components/Home/HomeBienvenida";
import HomeMapa from "../components/Home/HomeMapa";
import SitiosCount from "../components/Home/SitiosCount";
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import HomeSearch from "../components/Home/HomeSearch";
import { VeniAPerez } from "../components/Home/VeniAPerez";
import Contactanos from "../components/Home/Contactanos";
import { HomeAtraccionesImagenes } from "../components/Home/HomeAtraccionesImagenes";
import Countdown from "./Countdown";

const homerutasturisticas = () => {

    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <RutasTuristicasHomeBanner />
            <SitiosCount />
            <HomeSearch />
            <HomeBienvenida />
            <DistritosShowCase /> 
            <Contactanos />
            <HomeAtraccionesImagenes />
            <VeniAPerez />
            <HomeMapa/>
            <AtraccionesActionThree />
            <FooterRutas FooterData={FooterData} />
        </div>
    );
};
export default homerutasturisticas;
